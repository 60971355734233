<template>
  <v-card
    flat
    :ripple="false"
    elevation="0"
    class="d-flex"
    :class="getItemClasses"
    :href="itemImageHref(item)"
    :target="smAndDown ? '_self' : '_blank'"
    color="transparent"
    :width="itemColumnWidth"
    :min-height="!isComicSet ? getMinHeight : null"
    :style="is_item_selectable? 'pointer-events: none' :''"
  >
    <template v-if="isComicSet">
      <v-row
        class="comic-set-item ma-0 mb-1"
        :style="getRowStyles">
        <v-col
          v-for="(imageItem, index) in getItemImage"
          :key="index"
          :cols="index !== 0 ? 6 : 12"
          class="pa-0"
          :style="getComicSetItemStyle(index)">
          <v-img
            class="rounded fill-height"
            :class="layout === 'list' ? index !==0 ? 'rounded-lg rounded-t-0' : 'rounded-lg rounded-b-0' : index !==0 ? 'rounded-t-0' : 'rounded-b-0'"
            :src="imageItem ? compressedImageUrl(imageItem) : noImageSrc"
            :alt="item.title"
            @load="index === 2 ? handleImageLoad : null"
            style="overflow: visible">
            <v-scroll-y-transition v-if="is_item_selectable && index === 0">
              <v-layout>
                <v-checkbox
                    on-icon="mdi-check-circle"
                    off-icon="mdi-check-circle"
                    v-model="active"
                    class="ml-auto"
                    shrink mr-0 mt-0
                ></v-checkbox>
              </v-layout>
            </v-scroll-y-transition>
            <template v-if="isRecommendToFolder && index === 0">
              <audio-button
                v-if="item.audioUrl && item.vs_catalog_id"
                class="float-left"
                :item="item"
                :is_item_selectable="is_item_selectable"
                :layout="layout"
                :icon_width="iconWidth" />
              <slot name="addToFolderBtn"></slot>
            </template>
            <template v-else>
              <audio-button
                v-if="(item.audioUrl && item.vs_catalog_id) && index === 0"
                class="float-left"
                :item="item"
                :is_item_selectable="is_item_selectable"
                :layout="layout"
                :icon_width="iconWidth" />
              <add-to-favorite-button
                v-if="(visible_fav_btn && item.vs_catalog_id) && index === 0"
                class="float-right"
                :is_item_selectable="is_item_selectable"
                :layout="layout"
                :icon_width="iconWidth"
                :vs_catalog_id="item.vs_catalog_id"
                :is_favorite="item.isFavorite"></add-to-favorite-button>
            </template>
            <slot name="overlayMask"></slot>
            <add-to-cart-button
              v-if="index === 2 && visible_addCart_btn"
              :is_price_label_size_x_small="is_price_label_size_x_small"
              :layout="layout"
              :item="item"
              :visible_add-cart_btn="visible_addCart_btn"
              :is_cart_update="is_cart_update"
            />
          </v-img>
        </v-col>
      </v-row>
    </template>
    <v-img
      v-else
      class="mb-1 flex-grow-0 cart-item-image"
      :class="layout === 'list' ? 'rounded-lg mb-auto' : 'rounded mt-auto'"
      :src="item.hasOwnProperty('image_url') && item.image_url.length > 0 ? compressedImageUrl(item.image_url[0]) : noImageSrc"
      :alt="item.title"
      :width="layout === 'list' ? getColumnWidth('list') : null"
      :min-height="50"
      :max-height="layout === 'column' || layout === 'small-column' ? 300 : 400"
      contain
      :options="{rootMargin: '50%'}"
      :position="layout !== 'list' ? 'center bottom' : null"
      @load="handleImageLoad"
      style="overflow: visible"
    >
      <v-overlay
        v-if="!item.hasOwnProperty('image_url') || (item.hasOwnProperty('image_url') && item.image_url.length === 0)"
        absolute
        opacity="0"
        :dark="false"
        color="#2c2c2c"
        class="justify-start align-start"
        style="pointer-events: none"
      >
        <v-card-title
            class="mt-2 px-2 py-0 omitted"
            :class="layout === 'large-column' ? 'title' : 'body-2'">
          {{ item.title }}
        </v-card-title>
      </v-overlay>
        <v-layout v-if="is_item_selectable">
          <v-checkbox
            on-icon="mdi-check-circle"
            off-icon="mdi-check-circle"
            v-model="active"
            class="ml-auto"
            shrink mr-0 mt-0
          ></v-checkbox>
        </v-layout>
      <template v-if="isRecommendToFolder">
        <audio-button
          v-if="item.audioUrl && item.vs_catalog_id"
          :item="item"
          class="float-left"
          :is_item_selectable="is_item_selectable"
          :layout="layout"
          :icon_width="iconWidth"/>
        <slot name="addToFolderBtn"></slot>
      </template>
      <template v-else>
        <audio-button
          v-if="item.audioUrl && item.vs_catalog_id"
          class="float-left"
          :item="item"
          :is_item_selectable="is_item_selectable"
          :layout="layout"
          :icon_width="iconWidth"/>
        <add-to-favorite-button
          v-if="visible_fav_btn && item.vs_catalog_id"
          class="float-right"
          :is_item_selectable="is_item_selectable"
          :layout="layout"
          :icon_width="iconWidth"
          :vs_catalog_id="item.vs_catalog_id"
          :is_favorite="item.isFavorite"></add-to-favorite-button>
      </template>
      <slot name="overlayMask"></slot>
      <add-to-cart-button
        v-if="visible_addCart_btn"
        :is_price_label_size_x_small="is_price_label_size_x_small"
        :layout="layout"
        :item="item"
        :visible_add-cart_btn="visible_addCart_btn"
        :is_cart_update="is_cart_update"
      />
    </v-img>
    <template v-if="!hide_title">
      <v-card-title v-visible="item.title && (item.hasOwnProperty('image_url') && item.image_url.length !== 0)" class="pa-0 omitted mt-2">
        {{ item.title }}
      </v-card-title>
    </template>
    <slot name="vbItemList"></slot>
  </v-card>
</template>

<script>
import AudioButton from "./item/audio-button.vue";
import AddToFavoriteButton from "./library/add-to-favorite-button.vue";
import AddToCartButton from "./item/add-to-cart-button.vue";
import ItemConfig from "../../config/item.js";

export default {
  name: "vb-item",
  components: {
    AddToCartButton,
    AudioButton,
    AddToFavoriteButton,
  },
  props: {
    active: {type: Boolean, required: false, default: false},
    item: {type: Object, required: false, default: null},
    disable_itemimage_href: {type: Boolean, required: false, default: false},
    index: {type: Number, required: false, default: null},
    layout: {type: String, required: false, default: 'column'},
    hide_title: {type: Boolean, required: false, default: false},
    is_price_label_size_small: {type: Boolean, required: false, default: false},
    is_price_label_size_x_small: {type: Boolean, required: false, default: false},
    is_item_selectable: {type: Boolean, required: false, default: false},
    visible_fav_btn: {type: Boolean, required: false, default: true},
    is_cart_update: {type: Boolean, required: false, default: false},
    fixed_width: {type: String, required: false, default: '100'},
    visible_addCart_btn: {type: Boolean, required: false, default: true},
  },
  data() {
    return {
      cdnBaseUrl: VueConfig.cdn_base_url,
      cdnUrl: VueConfig.cdn_url,
      noImageSrc: VueConfig.cdn_url + '/assets/images/common/no_image_bg_straight.svg',
      itemXXS: ItemConfig.columnWidth.itemXXS,
      itemXS: ItemConfig.columnWidth.itemXS,
      itemSM: ItemConfig.columnWidth.itemSM,
      itemMD: ItemConfig.columnWidth.itemMD,
      itemLG: ItemConfig.columnWidth.itemLG,
      itemXL: ItemConfig.columnWidth.itemXL,
      conditionCodeType: VueConfig.conditionCode,
      isCompressedImageLoaded: false,
      loadedCompressedImageWidth: null
    }
  },
  computed: {
    getItemImage() {
      if (this.item.image_url) {
        return this.item.image_url
      } else if (this.item.imageUrl) {
        return this.item.imageUrl
      } else {
        return ''
      }
    },
    getItemClasses() {
      if (this.layout === 'list') return 'mb-8 align-start';
      if (this.layout === 'row') return 'mx-4 mb-3 mx-md-5 mb-md-4 flex-column';
      if (this.layout === 'fixed-column') return 'mx-2 mb-8 mx-md-4 mb-md-8 flex-column';
      return this.layout === 'column' || this.layout === 'small-column' ? 'mx-1 mb-3 mx-md-2 mb-md-3 flex-column' : 'mx-1 mb-2 mx-md-3 mb-md-4 flex-column';
    },
    getRowStyles() {
      switch (this.layout) {
        case 'small-column':
          return 'max-width: ' + this.getColumnWidth('small') + 'px';
        case 'column':
          return 'max-width: ' + this.getColumnWidth('medium') + 'px';
        case 'large-column':
          return 'max-width: ' + this.getColumnWidth('large') + 'px';
        case 'list':
          return 'max-width: ' + this.getColumnWidth('list') + 'px';
        case 'row':
          return 'max-width: ' + this.getColumnWidth('row') + 'px';
        case 'fixed-column':
          return 'max-width: ' + this.fixed_width + 'px';
      }
    },
    getComicSetItemStyle() {
      return function (index) {
        let offset = 0;
        let height;
        if (this.smAndDown || this.layout === 'small-column' || this.layout === 'fixed-column') offset = 20;
        if (index !== 0) {
          height = this.layout === 'column' || 'small-column' ? 78 - offset : 98 - offset;
        } else {
          height = this.itemColumnWidth;
        }
        return 'height: ' + height + 'px';
      }
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    itemColumnWidth() {
      if (this.layout === 'list') return '100%';
      switch (this.layout) {
        case 'small-column':
          this.$emit('column-width', this.getColumnWidth('small'))
          return this.getColumnWidth('small');
        case 'column':
          this.$emit('column-width', this.getColumnWidth('medium'))
          return this.getColumnWidth('medium');
        case 'large-column':
          this.$emit('column-width', this.getColumnWidth('large'))
          return this.getColumnWidth('large');
        case 'list':
          this.$emit('column-width', this.getColumnWidth('list'))
          return this.getColumnWidth('list');
        case 'row':
          this.$emit('column-width', this.getColumnWidth('row'))
          return this.getColumnWidth('row');
        case 'fixed-column':
          this.$emit('column-width', this.fixed_width)
          return this.fixed_width;
      }

    },
    getColumnWidth: function () {
      if (this.$vuetify.breakpoint.width <= 320) {
        return (layout) => {
          return this.itemXXS[layout]
        }
      }
      return (layout) => {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return this.itemXS[layout]
          case 'sm':
            return this.itemSM[layout]
          case 'md':
            return this.itemMD[layout]
          case 'lg':
            return this.itemLG[layout]
          case 'xl':
            return this.itemXL[layout]
        }
      }
    },
    getMinHeight() {
      return (this.itemColumnWidth === '100%' ? this.getColumnWidth('medium') : this.itemColumnWidth) * 1.5
    },
    isComicSet() {
      return this.item.isParentItemSet && this.item.hasOwnProperty('image_url') && this.item.image_url.length === 3;
    },
    iconWidth() {
      const layout = this.layout;
      switch (layout) {
        case 'column':
          return this.smAndDown ? 20 : 24;
        case 'small-column':
        case 'fixed-column':
        case 'row':
          return 20;
        case 'large-column':
          return this.smAndDown ? 24 : 32;
        default:
          return 24;
      }
    },
    isRecommendToFolder() {
      return this.$scopedSlots.addToFolderBtn;
    },
    compressedImageUrl() {
      return (imageUrl) => {
        if (!imageUrl) {
          return null;
        }
        // baseURLがcdnのbaseURLと一致しない場合はそのまま返す
        if (!imageUrl.startsWith(this.cdnBaseUrl)) {
          return imageUrl;
        }
        const width = this.smAndDown ? 140 : 244;
        const compressedImageUrl = imageUrl + `?width=${width}`;

        if (this.isCompressedImageLoaded && this.loadedCompressedImageWidth) {
          return imageUrl + `?width=${this.loadedCompressedImageWidth}`;
        }

        this.isCompressedImageLoaded = true;
        this.loadedCompressedImageWidth = width;
        return compressedImageUrl;
      };
    }
  },
  methods: {
    itemImageHref(item) {
      if (this.disable_itemimage_href) {
        return undefined;
      }
      if (!item.vs_catalog_id) {
        return undefined;
      }
      if (!item.title_for_url) {
        return `/bp/${item.vs_catalog_id}`;
      }
      return `/${item.title_for_url}/bp/${item.vs_catalog_id}`;
    },
    handleImageLoad(event) {
      if (event && event.target) {
        const imgElement = event.target;
        if (imgElement.src) {
          imgElement.src = this.compressedImageUrl(imgElement.src);
        }
        this.$emit('loaded');
      }
    },
  },
}
</script>

<style scoped lang="scss">
.v-card--link:focus:before {
  content: none !important;
}

.omitted {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 1.4em;
  height: calc(1.4em * 2);
  font-size: .75rem;
  font-weight: 400;
  letter-spacing: .0333333333em;
  font-family: Roboto, sans-serif;
}

.icon-price {
  max-width: 20px;

  &.x-small {
    max-width: 15px
  }

  .cls-1 {
    fill: none
  }

  .a, .cls-2 {
    fill: #FFF
  }
}

::v-deep {
  .v-image {
    .omitted {
      -webkit-line-clamp: 5;
      height: calc(1.4em * 5);
    }
    &__image {
      border-radius: 4px;
    }
  }
  .comic-set-item {
    position: relative;
    .v-image.rounded-t-0 {
      .v-image__image {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    .col {
      &:nth-child(1) {
        margin-bottom: 0.1875em;
        .v-image__image {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &:nth-child(2) {
        .v-image {
          margin-right: 0.125em;
          &__image {
            border-bottom-right-radius: 0;
          }
        }

      }

      &:nth-child(3) {
        .v-image {
          margin-left: 0.125em;
          &__image {
            border-bottom-left-radius: 0;
          }
        }
      }
    }

    .price {
      line-height: 1em;
    }
  }
}


</style>